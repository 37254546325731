import { useContext, useState, useEffect } from 'react';

import {
  Box,
  alpha,
  Stack,
  lighten,
  Divider,
  IconButton,
  Tooltip,
  styled,
  useTheme
} from '@mui/material';
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import { SidebarContext } from 'src/contexts/SidebarContext';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';

import HeaderButtons from './Buttons';
import HeaderUserbox from './Userbox';
import HeaderMenu from './Menu';
import { getCurrentUser } from 'src/services/AuthServices/authService';
import { User } from 'src/models/user';

const HeaderWrapper = styled(Box)(
  ({ theme }) => `
        height: ${theme.header.height};
        color: ${theme.header.textColor};
        padding: ${theme.spacing(0, 2)};
        right: 0;
        z-index: 6;
        background-color: ${alpha(theme.header.background, 0.95)};
        backdrop-filter: blur(3px);
        position: fixed;
        justify-content: space-between;
        width: 100%;
        @media (min-width: ${theme.breakpoints.values.lg}px) {
            left: ${theme.sidebar.width};
            width: auto;
        }
`
);

function Header() {
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const theme = useTheme();
  const [CurrentUser, SetCurrentUser] = useState<any | null>(null);
  useEffect(() => {
    const loaddata = async () => {
      const user = await getCurrentUser();
      SetCurrentUser(user);
      //   setLoading(false); // Set loading to false when data is fetched
    };
    // memoizedAuthorizeUser();
    loaddata();
  }, []);
  return (
    <HeaderWrapper
      display="flex"
      alignItems="center"
      sx={{
        boxShadow:
          theme.palette.mode === 'dark'
            ? `0 1px 0 ${alpha(
                lighten(theme.colors.primary.main, 0.7),
                0.15
              )}, 0px 2px 8px -3px rgba(0, 0, 0, 0.2), 0px 5px 22px -4px rgba(0, 0, 0, .1)`
            : `0px 2px 8px -3px ${alpha(
                theme.colors.alpha.black[100],
                0.2
              )}, 0px 5px 22px -4px ${alpha(
                theme.colors.alpha.black[100],
                0.1
              )}`
      }}
    >
      <Stack
        direction="row"
        divider={<Divider orientation="vertical" flexItem />}
        alignItems="center"
        spacing={2}
      >
        {/* <HeaderMenu /> */}
      </Stack>
      <Box display="flex" alignItems="center">
        <HeaderButtons />
        <HeaderUserbox
          first_name={CurrentUser?.first_name}
          last_name={CurrentUser?.last_name}
          country={CurrentUser?.country}
          current_voucher={CurrentUser?.current_voucher}
          data_joined={CurrentUser?.data_joined}
          email={CurrentUser?.email}
          gender={CurrentUser?.gender}
          id={CurrentUser?.id}
          last_login={CurrentUser?.last_login}
          parent_user={CurrentUser?.parent_user}
          phone={CurrentUser?.phone}
          referral_code={CurrentUser?.referral_code}
          role={CurrentUser?.role}
          subscription_status={CurrentUser?.subscription_status}
          subscription_type={CurrentUser?.subscription_type}
          watch_ads_count={CurrentUser?.watch_ads_count}
          watch_ads_status={CurrentUser?.watch_ads_status}
        />
        <Box
          component="span"
          sx={{
            ml: 2,
            display: { lg: 'none', xs: 'inline-block' }
          }}
        >
          <Tooltip arrow title="Toggle Menu">
            <IconButton color="primary" onClick={toggleSidebar}>
              {!sidebarToggle ? (
                <MenuTwoToneIcon fontSize="small" />
              ) : (
                <CloseTwoToneIcon fontSize="small" />
              )}
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
    </HeaderWrapper>
  );
}

export default Header;
