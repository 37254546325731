import axios from "axios";
import { User } from "src/models/user";
export const login = async (loginForm: LoginFormState): Promise<void> => {
  try {
    const response = await axios.post(
      'https://api.alphanetverse.com/api/v1/auth/jwt/',
      loginForm
    );
    if (response.status === 200) {
      const access = response.data.access;
      localStorage.setItem("token", access);
    } else {
      console.error("Signin failed.");
    }
  } catch (error) {
    console.error("Signin failed. An error occurred.", error);
    // Notiflix.Notify.failure("Vérifiez votre e-mail ou mot de passe", {
    //   position: "center-top",
    // });
  }
};

export const getCurrentUser = async (): Promise<any> => {
  try {
    const response = await axios.get(
      'https://api.alphanetverse.com/api/v1/me/',
      {
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${localStorage.getItem('token')}`
        }
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching Current User:', error);
    throw new Error('Failed to fetch Current User.');
  }
};
type LoginFormState = {
  email: string;
  password: string;
};

export const logout = async (): Promise<void> => {
  try {
    localStorage.removeItem('token');
    // You can also perform any other cleanup tasks here if needed
  } catch (error) {
    console.error('Logout failed. An error occurred.', error);
    throw new Error('Failed to logout.');
  }
};


type SignUpFormState = {
  first_name: string;
  last_name: string;
  phone: string;
  country: string;
  email: string;
  role: string;
  password: string;
  gender: string;

}

export const signup = async (signupForm: SignUpFormState, referralCode: string): Promise<void> => {
  const url = `https://api.alphanetverse.com/api/v1/auth/register/${referralCode}`;
  try {
    const response = await axios.post(url, signupForm);
    if (response.status === 201) {
      console.log("Signup successful.");
    } else {
      console.error("Signup failed with status:", response.status);
    }
  } catch (error) {
    if (error.response) {
      console.error("Signup failed. Server responded with:", error.response.data);
    } else if (error.request) {
      console.error("Signup failed. No response received.");
    } else {
      console.error("Signup failed. An error occurred:", error.message);
    }
  }
};

type forgetFormState = {
  email: string;

}
export const resetPassword = async (forgetForm: forgetFormState): Promise<void> => {
  const url = 'https://api.alphanetverse.com/api/v1/auth/password-reset/';

  try {
    const response = await axios.post(url, forgetForm);

    if (response.status === 200) {
      console.log("Password reset email sent.");
      console.log(response.status);
    } else {
      console.error("Signup failed with status:", response.status);
    }
  } catch (error) {
    console.error("An error occurred while trying to send a password reset email.", error);
  }
};

export const updateProfilePicture = async (file: File): Promise<void> => {
  const formData = new FormData();
  formData.append('profile_picture', file);

  const url = 'https://api.alphanetverse.com/api/v1/me/profile_picture/';
  try {
    const response = await axios.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
    if (response.status === 200) {
      console.log("Profile picture updated successfully.");
    } else {
      console.error("Profile picture update failed with status:", response.status);
    }
  } catch (error) {
    console.error("An error occurred while trying to update the profile picture.", error);
  }
}