import axios, { AxiosError } from 'axios';


export const getSubaccounts = async () => {
  const response = await axios.get(
    'https://api.alphanetverse.com/api/v1/me-profiles/',
    {
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${localStorage.getItem('token')}`
      }
    }
  );
  return response.data;
};

export const getProfilePicture = async () => {
  const response = await axios.get(
    'https://api.alphanetverse.com/api/v1/me/profile_picture/',
    {
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${localStorage.getItem('token')}`
      }
    }
  );
  return response.data;
}