import React from 'react';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

const useAuthorization = () => {
  const navigate = useNavigate();

  const AuthorizeUser = () => {
    const token = localStorage.getItem('token');

    if (token) {
      try {
        // const decodedToken: any = jwtDecode(token);
        // const { role } = decodedToken;
        // if (role === 1) {
        navigate('/welcome');
        // }
      } catch (error) {
        console.error('Invalid token:', error);
        navigate('/');
      }
    } else {
      navigate('/');
    }
  };

  return { AuthorizeUser, navigate };
};
export const UserId = (): string | null => {
  const token = localStorage.getItem('token');
  if (token) {
    try {
      const decodedToken: any = jwtDecode(token);
      const { id } = decodedToken;
      return id;
    } catch (error) {
      console.error('Invalid token:', error);
    }
  }

  return null; // Return null if there is no valid token or an error occurs
};
export default useAuthorization;