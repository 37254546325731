import axios, { AxiosError } from 'axios';

export const GetUserNotifications = async () => {
  const response = await axios.get(
    'https://api.alphanetverse.com/api/v1/me/notifications/',
    {
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${localStorage.getItem('token')}`
      }
    }
  );
  return response.data;
};
export const markAllAsRead = async () => {
  try {
    const response = await axios.post(
      'https://api.alphanetverse.com/api/v1/me/notifications/mark-all-as-read/',
      {
        // Empty body
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error marking all notifications as read:', error);
    throw error;
  }
};