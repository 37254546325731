import { Avatar, Button, Container, CssBaseline, FormControlLabel, Grid, Link, makeStyles, TextField, Typography } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react'
import { resetPassword } from 'src/services/AuthServices/authService';
import useAuthorization from 'src/services/AuthServices/useAuthorization';


type forgtformstate= {
    email: string;
  };
  
const useStyles = makeStyles((theme) => ({
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      height: '100px',
      width: '100px',
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
  }));
  
  
const FogetPassword: React.FC = () => {
    
  
   
    const handleforget = async (event: React.FormEvent): Promise<void> => {
        event.preventDefault();
    
        try {
          resetPassword(forgtform)
        } catch (error) {
          console.error("Signup failed with status:");        }
      };



      const [forgtform, setforgetForm] = useState<forgtformstate>({
        email: '',
      });
    
      const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setforgetForm({ ...forgtform, email: e.target.value });
      };


    const classes = useStyles();

  return (
    <Container component="main" maxWidth="xs">
    <CssBaseline />
    <div className={classes.paper}>
      <Avatar className={classes.avatar} alt="Netvesre"
            src="/static/images/logo/netverse-logo.png">
      </Avatar>
      <Typography component="h1" variant="h5">
        Sign in
      </Typography>
      <form className={classes.form}  onSubmit={handleforget}>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          autoFocus
          placeholder="mail@Netcerse.com"
          type="text"
          value={forgtform.email}
          onChange={handleEmailChange}
        />
        
        
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
        >
          Send Request
        </Button>
        <Grid container>
          <Grid item xs>
          <Link href="/" variant="body2">
              {"click here to login"}
            </Link>
          </Grid>
          <Grid item>
            <Link href="/signup" variant="body2">
              {"Don't have an account? Sign Up"}
            </Link>
          </Grid>
        </Grid>
      </form>
    </div>

  </Container>
  )
}

export default FogetPassword

function AuthorizeUser() {
  throw new Error('Function not implemented.');
}
