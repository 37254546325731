
import React, { useCallback, useEffect, useState } from 'react';

import useAuthorization from 'src/services/AuthServices/useAuthorization';
import { login, signup } from 'src/services/AuthServices/authService';
import Checkbox from 'src/components/checkbox';
import { makeStyles } from '@material-ui/core/styles';
import { useNavigate } from 'react-router-dom';
import { Avatar, Button, Container, CssBaseline, FormControlLabel, Grid, InputLabel, Link, MenuItem, Select, TextField, Typography } from '@mui/material';
type SignUpFormState = {
    first_name: string;
    last_name: string;
    phone: string;
    country: string;
    email: string;
    role: string;
    password: string;
    gender: string;
    // Add the missing referralCode property
}


const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        height: '100px',
        width: '100px',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));



const SignUp: React.FC = () => {
    const classes = useStyles();
    const [error, setError] = useState<string>('');
    const [referralCode, setReferralCode] = useState('');
    const [signUpSuccess, setSignUpSuccess] = useState(false);
    const navigate = useNavigate();

    const performSignup = async (event: React.FormEvent): Promise<void> => {
        event.preventDefault();

        try {
            await signup(SignUpForm, referralCode);
            setSignUpSuccess(true);
            setSignUpForm({
                first_name: '',
                last_name: '',
                phone: '',
                country: '',
                email: '',
                role: 'User',
                password: '',
                gender: '',
            });
            navigate('/');
        } catch (error) {
            console.error('Signup failed. An error occurred.', error);
            window.alert('Signup failed. Please try again.');
        }
    };


    const [SignUpForm, setSignUpForm] = useState<SignUpFormState>({
        first_name: '',
        last_name: '',
        phone: '',
        country: '',
        email: '',
        role: 'User',
        password: '',
        gender: '',
    });

    const handlefirstNameChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setSignUpForm({ ...SignUpForm, first_name: e.target.value });
    };

    const handlelastNameChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setSignUpForm({ ...SignUpForm, last_name: e.target.value });
    };
    const handlephoneChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setSignUpForm({ ...SignUpForm, phone: e.target.value });
    };
    const handlecountryChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setSignUpForm({ ...SignUpForm, country: e.target.value });
    };
    const handlegenderChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setSignUpForm({ ...SignUpForm, gender: e.target.value });
    };
    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setSignUpForm({ ...SignUpForm, email: e.target.value });
    };
    const handlePasswordChange = (
        e: React.ChangeEvent<HTMLInputElement>
    ): void => {
        setSignUpForm({ ...SignUpForm, password: e.target.value });
    };

    const [showPassword, setShowPassword] = useState(false);
    const handleTogglePassword = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };
    return (
        <Container component="main" maxWidth="xs" style={{ marginTop: '50px', marginBottom: '50px' }}>
            <CssBaseline />
            <div className={classes.paper}>
                <Avatar className={classes.avatar} alt="Netvesre"
                    src="/static/images/logo/netverse-logo.png">
                </Avatar>
                <Typography component="h1" variant="h5">
                    Sign UP
                </Typography>
                <form className={classes.form} onSubmit={performSignup}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="ReferralCode"
                        label="ReferralCode"
                        name="Referral Code"
                        autoComplete="Referral Code"
                        autoFocus
                        placeholder="Referral Code"
                        type="text"
                        value={referralCode}
                        onChange={(event) => setReferralCode(event.target.value)}

                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="firstName"
                        label="firstName"
                        name="email"
                        autoComplete="firstName"
                        autoFocus
                        placeholder=" First Name"
                        type="text"
                        value={SignUpForm.first_name}
                        onChange={handlefirstNameChange}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="lastName"
                        label="LastName"
                        name="email"
                        autoComplete="Last Name"
                        autoFocus
                        placeholder="last Name"
                        type="text"
                        value={SignUpForm.last_name}
                        onChange={handlelastNameChange}
                    />

                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="phone"
                        label="Phone"
                        name="phone"
                        autoComplete="+216...."
                        autoFocus
                        placeholder="phone"
                        type="text"
                        value={SignUpForm.phone}
                        onChange={handlephoneChange}
                    />
                    {/* <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="country"
                        label="country"
                        name="country"
                        autoComplete="country"
                        autoFocus
                        placeholder="country"
                        type="text"
                        value={SignUpForm.country}
                        onChange={handlecountryChange}
                    /> */}

                    <Grid container spacing={2}>
                        <Grid item xs={3}>

                            <InputLabel id="country">country</InputLabel>
                            <Select
                                labelId="country"
                                required
                                id="country"

                                value={SignUpForm.country}
                                onChange={handlecountryChange}
                                label="country"
                                autoComplete="country"
                            >
                                <MenuItem value="TN">Tunis</MenuItem>
                                <MenuItem value="MA">Morocco</MenuItem>
                                <MenuItem value="LY">Lybia</MenuItem>
                                <MenuItem value="EG">egypt</MenuItem>

                            </Select>
                        </Grid>
                        <Grid item xs={6}>
                            <InputLabel id="gender-label">Gender</InputLabel>
                            <Select
                                labelId="gender-label"
                                required
                                id="gender"

                                value={SignUpForm.gender}
                                onChange={handlegenderChange}
                                label="Gender"
                                autoComplete="gender"
                            >
                                <MenuItem value="M">Male</MenuItem>
                                <MenuItem value="F">Female</MenuItem>

                            </Select>
                        </Grid>
                    </Grid>


                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        placeholder="mail@Netcerse.com"
                        type="text"
                        value={SignUpForm.email}
                        onChange={handleEmailChange}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        value={SignUpForm.password}
                        onChange={handlePasswordChange}
                    />

                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                        Sign UP
                    </Button>
                    <Grid container>

                        <Grid item>
                            <Link href="/Signin" variant="body2">
                                {"if you have an account? login"}
                            </Link>
                        </Grid>
                    </Grid>
                </form>
            </div>

        </Container>
    );
};
export default SignUp;
